<template>
  <div class="page-products-create">
    <back />
    <page-title title="Добавить товар" description="На этой странице вы можете создать товар" />
    <n-loader :loading="$var('load')" />

    <div class="container">
      <n-form @submit="submit">
        <n-image-upload :class="[{'danger-border': $form.errors('files')}]" :file="$form.get('files')" :from="photoSource"
                        multi @update:file="(v)=>$form.set('files', v)" />
        <div v-if="$form.errors('files')" class="danger">{{ $form.errors('files') }}</div>

        <div class="img-list">
          <div v-for="(file, i) in $form.get('files')" :key="file.name" class="wrap" @click="remove(i)">
            <div class="item">
              <img :src="getImage(file)" alt="">
            </div>
          </div>
        </div>

        <n-items>
          <n-input v-bind="$form.input('title')" title="Название товара" />
          <div class="cost-count">
            <n-input v-bind="$form.input('cost')" type="number" title="Стоимость товара" />
            <n-input v-if="hasStore" v-bind="$form.input('count')" type="number" title="Количество" />
          </div>

          <n-link v-if="$app.auth.user().hasStore" :to="{name: 'categories.list', query: { create: null}}" style="width: 100%">
            <n-input :value="$form.get('category')" title="Категория товара" />
          </n-link>
          <n-link v-else :to="{name: 'categories.sub', params: { id: 1, }, query: { create: null}}" style="width: 100%">
            <n-input :value="$form.get('category')" title="Категория товара" />
          </n-link>

          <n-input v-if="hasStore" v-bind="$form.input('code')" title="Артикул" />

          <n-form-item title="Описание товра">
            <textarea v-bind="$form.input('description')" @input="(e) => $form.set('description', e.target.value)" />
          </n-form-item>

          <n-button wide color="primary" type="submit">Опубликовать</n-button>
        </n-items>
      </n-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageProductsCreate',
  data() {
    return {
      category: [],
      src: '',
    }
  },
  computed: {
    hasStore() {
      return $app.auth.user().hasStore
    },
    photoSource() {
      return this.hasStore ? 'both' : 'camera'
    },
  },
  watch: {
    'form_inputs.default'(value) {
      $app.store.mutation('app.product', value)
    },
  },
  created() {
    this.$form.init($app.store.getter('app.product'))
    if (this.$route.params.category) {
      this.$form.set('category', this.$route.params.category)
    }
    this.$form.rules({
      cost: [ 'required', ],
      title: [ 'required', ],
      files: [ 'required', ],
    })
  },
  methods: {
    submit() {
      if (!this.$form.check()) {
        return
      }
      this.$var('load', true)
      const apis = $n.reduce(this.$form.get('files'), (result, file) => {
        result.push($api.files.create({ file, }))
        return result
      }, [])

      Promise.all(apis).then((responses) => {
        const images = $n.reduce(responses, (result, item) => {
          result.push(item.data.content.id)
          return result
        }, [])

        const data = {
          storeId: $app.auth.user().store?.id,
          ...this.$form.get(),
          categoryId: this.$form.get('category')?.id,
          category: undefined,
          files: undefined,
          images,
        }
        $api.app.products.create(data).then((response) => {
          $app.store.mutation('app.product', {})
          this.$router.push({ name: 'products.index', params: { id: response.data.content.id, }, })
        }).finally(() => {
          this.$var('load', false)
        })
      }).catch(() => {
        this.$var('load', false)
      })
    },
    remove(i) {
      const array = this.$form.get('files')
      array.splice(i, 1)
      this.$form.set('files', array)
    },
    getImage(file) {
      return URL.createObjectURL(file)
    },
  },
}
</script>

<style scoped lang="scss">
.page-products-create {
  padding-bottom: 10px;

  .cost-count {
    display: flex;
    width: 100%;
    & > *:nth-child(1) { margin-right: 10px; max-width: 50%; }
    & > *:nth-child(2) { margin-left: 10px; }
  }

  .img-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .wrap {
      padding: 5px;
      width: 33.333333%;
    }

    .item {
      height: 74px;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  textarea {
    font-size: 14px;
    padding: 12px 15px;
    width: 100%;
    min-height: 130px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #fff;

    &:focus-visible {
      border: 1px solid var(--primary);
      outline: none;
    }

    &::placeholder {
      font-size: 14px;
      color: transparent;
    }
  }

  .n-form {
    .n-link {
      color: inherit;
    }
  }

  .danger {
    margin-top: 5px;
    color: var(--danger);
  }

  .danger-border {
    border: 1px solid var(--danger);
    border-radius: 10px;
  }
}
</style>
